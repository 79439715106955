<template>
  <div class="content">
    <h1 class="">Login</h1>

    <div class="error" v-if="error">{{ error }}</div>

    <form @submit.prevent="signIn">
      <div class="field">
        <label for="email">Email</label>
        <input name="email" type="email" v-model="email" disabled />
      </div>

      <div class="field">
        <label for="password">Password</label>
        <input name="password" type="password" v-model="password" />
      </div>

      <!-- <button type="submit">Login</button> -->
      <div class="field">
        <button type="submit">Sign In</button>
      </div>
    </form>

    <!-- <button @click="logInWithGoogle">
      <svg
        aria-hidden="true"
        class="native svg-icon iconGoogle"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path
          d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z"
          fill="#2c3e50"
        ></path>
        <path
          d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z"
          fill="#2c3e50"
        ></path>
        <path
          d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z"
          fill="#2c3e50"
        ></path>
        <path
          d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z"
          fill="#2c3e50"
        ></path>
      </svg>
      <span>Login With Google</span>
    </button> -->
  </div>
</template>

<script>
/**
 * import { getAuth } from "firebase/auth";
 * const auth = getAuth();
 */
import { ref } from "@vue/reactivity";
import { useRouter } from 'vue-router';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

export default {
  setup() {
    const email = ref("ejosephat@gmail.com");
    const password = ref("");
    const error = ref("");
    const auth = getAuth();
    const router = useRouter();
    // const provider = new firebase.auth.GoogleAuthProvider();

    const signIn = async (e) => {
      const { email, password } = e.target.elements
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value)
        router.push("/")
      } catch (error) {
        console.log(error.message)
      }
    };

    /**
    * const logInWithGoogle = () => {
    *   console.log("logInWithGoogle");
    *   firebase
    *     .auth()
    *     .signInWithPopup(provider)
    *     .then(() => {
    *       console.log('sign in with google');
    *       router.replace({ name: "Gallery"})
    *     })
    *     .catch((err) => {
    *       console.log("err");
    *       console.log(err);
    *     });
    * };
    */

    return { email, password, error, signIn };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-bottom: 75px;
  max-width: 48em;
}

.field {
  display: flex;
  flex-direction: column;
  margin: 30px 0
}

label {
  margin-bottom: 10px;
}

input {
  height: 40px;
  padding: 2px 10px;

  [type=text],
  [type=password] {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 18px;
  }
}

button {
  height: 48px;
  min-width: 120px;
}

button[type=submit] {
  background-color: #2c3e50;
  border: none;
  color: white;
  text-transform: uppercase;
  width: 120px;
}

// button[type=submit],
// button {
// }


// button {
//   align-items: center;
//   border-color: #2c3e50;
//   display: flex;
//   padding: 2px 10px;

//   span {
//     padding-left: 10px;
//     text-transform: uppercase;
//   }
// }

// button,
// input[type=button] {
//   min-width: 228px;
//   height: 52px;
// }
</style>
